import React from 'react';

const misscolle = require('./assets/misscolle.svg');
const mrcolle = require('./assets/mrcolle.svg');
const mensrize = require('./assets/mensrize.svg');
const rize = require('./assets/rize.svg');
const modelpress = require('./assets/modelpress.svg');
const nakamura = require('./assets/nakamura.jpg');
const kusachi = require('./assets/kusachi.jpg');
const anatore = require('./assets/anatore.png');
const dhc = require('./assets/dhc.png');
const mysta = require('./assets/mysta.png');
const showroom = require('./assets/showroom.png');
const nonstyle = require('./assets/nonstyle.jpg');
const Information: React.FC = () => {
  return (
    <>
      <div className="is-margin-bottom-3 is-margin-top-2">
        <h1 className="title is-gray is-size-6 has-text-weight-bold as-section-title">MC</h1>
        <h2 className="subtitle" />
      </div>
      <div className="columns is-centered is-mobile is-margin-bottom-6">
        <div className="column is-8 has-text-centered">
          <p className="is-size-6 is-margin-bottom-1">NON STYLE</p>
          <figure className="image is-max-480x480" style={{ margin: '0 auto' }}>
            <img src={nonstyle} width={320} />
          </figure>
        </div>
      </div>
      <div className="is-margin-bottom-3">
        <h1 className="title is-gray is-size-6 has-text-weight-bold as-section-title">ゲストプレゼンター</h1>
        <h2 className="subtitle" />
      </div>
      <div className="columns is-centered is-mobile is-margin-bottom-2">
        <div className="column is-6 has-text-centered">
          <p className="is-size-6 is-margin-bottom-1">中村 優花</p>
          <figure className="image is-max-256x256" style={{ margin: '0 auto' }}>
            <img src={nakamura} width={240} />
          </figure>
        </div>
        <div className="column is-6 has-text-centered">
          <p className="is-size-6 is-margin-bottom-1">草地 稜之</p>
          <figure className="image is-max-256x256" style={{ margin: '0 auto' }}>
            <img src={kusachi} width={240} />
          </figure>
        </div>
      </div>
      <table className="table is-fullwidth as-information">
        <tbody>
          <tr>
            <th className="is-narrow">日時</th>
            <td>2020年3月26日(木)</td>
          </tr>
          <tr>
            <th className="is-narrow">会場</th>
            <td>マイナビBLITZ赤坂</td>
          </tr>
          <tr>
            <th className="is-narrow">主催</th>
            <td>学生団体MARKS / 株式会社エイジ・エンタテインメント</td>
          </tr>
          <tr>
            <th className="is-narrow">企画制作</th>
            <td>
              <div className="images">
                <figure className="image">
                  <img src={misscolle} />
                </figure>
                <figure className="image">
                  <img src={mrcolle} />
                </figure>
              </div>
            </td>
          </tr>
          <tr>
            <th className="is-narrow">特別協賛</th>
            <td>
              <div className="images">
                <figure className="image">
                  <img src={rize} style={{maxHeight: 50}} />
                </figure>
                <figure className="image">
                  <img src={mensrize} style={{maxHeight: 50}} />
                </figure>
              </div>
            </td>
          </tr>
          <tr>
            <th className="is-narrow">特別協力</th>
            <td>
              <div className="images">
                <figure className="image">
                  <img src={modelpress} alt="モデルプレス" />
                </figure>
              </div>
            </td>
          </tr>
          <tr>
            <th className="is-narrow">協賛</th>
            <td>
              <div className="images">
                <figure className="image">
                  <img src={anatore}　style={{maxHeight: 30}} />
                </figure>
                <figure className="image" style={{marginTop:4}}>
                  <img src={dhc}　style={{maxHeight: 33}} />
                </figure>
                <figure className="image">
                  <img src={mysta} style={{maxHeight: 25}} />
                </figure>
                <figure className="image" >
                  <img src={showroom} style={{maxHeight: 25}} />
                </figure>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Information;
