import React from 'react';
import * as Navi from 'navi';
import { list } from '../json/entry.json';
import ProfileApp from "../apps/ProfileApp";

let profiles = list
    .map(entry => ({
        id: entry.id,
        getPage: Navi.map(async () => {
            return Navi.route({
                title: `${entry.name} | ${process.env.REACT_APP_WEBSITE_NAME}`,
                getView: () => {
                    return <ProfileApp entry={entry} />;
                },
            });
        }),
    }));

export default profiles;