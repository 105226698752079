import React from 'react';
import { Link } from 'react-navi';

interface HeaderProps {
  logo: string;
  datetime: string;
  additional: any;
}

const Header: React.FC<HeaderProps> = props => (
  <>
    <nav className="navbar is-black" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" href="/">
          <img src={props.logo} height="28" alt={process.env.REACT_APP_WEBSITE_NAME} />
        </Link>
        <div className="navbar-item is-padding-left-0">
          <img src={props.datetime} height="28" />
        </div>
      </div>
    </nav>
    <div className="tabs as-navigation is-centered is-family-futura is-margin-bottom-0 has-background-dark">
      <ul>
        <li>
          <Link href="/finalist">FINALIST</Link>
        </li>
        <li>
          <Link href="/#schedule">SCHEDULE</Link>
        </li>
        <li className="is-hidden-mobile">
          <Link href="/#photo">PHOTO</Link>
        </li>
        <li>
          <a href="/#history">HISTORY</a>
        </li>
        {props.additional}
      </ul>
    </div>
  </>
);

export default Header;
