import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';
import Entry from '../types/entry';
import { LazyImageFull, ImageState } from 'react-lazy-images';

interface CardProps {
  entry: Entry;
  loadingImage: any;
  nativeLink?: boolean;
  baseUrl?: string;
}


const Card: React.FC<CardProps & Link.Props> = props => {
  const src =
    props.entry.images.length > 0
      ? `${props.baseUrl || ""}/image/entries/${props.entry.id}/${props.entry.images[0]}.jpg`
      : '${props.baseUrl || ""}/image/noimage.jpg';
  return (
    <div className={classNames("candidate-card", props.className)}>
      {props.nativeLink ?
          <a href={props.href as string}>
            <figure className="image is-1by1">
              <picture>
                <LazyImageFull src={src} alt={props.entry.name}>
                  {({ imageProps, imageState, ref }) => {
                    if (imageState === ImageState.NotAsked) {
                      return <img src={props.loadingImage} ref={ref} />;
                    }
                    return <img {...imageProps} ref={ref} />;
                  }}
                </LazyImageFull>
                <figcaption className="candidate-mask">
                  <figure className="image is-1by1">
                    <svg width="240" height="240" viewBox="0 0 240 240">
                      <path fill="none" d="M15,14.5 v210 h210"></path>
                      <path fill="none" d="M225,225 v-210 h-210"></path>
                    </svg>
                    <figcaption>
                      <p>詳しく見る</p>
                    </figcaption>
                  </figure>
                </figcaption>
              </picture>
            </figure>
            <div className="candidate-card-title">
              <p className="candidate-card-title-name">{props.entry.name}</p>
              <p className="candidate-card-title-college">{props.entry.college}</p>
            </div>
          </a>
          :
          <Link href={props.href}>
            <figure className="image is-1by1">
              <picture>
                <LazyImageFull src={src} alt={props.entry.name}>
                  {({ imageProps, imageState, ref }) => {
                    if (imageState === ImageState.NotAsked) {
                      return <img src={props.loadingImage} ref={ref} />;
                    }
                    return <img {...imageProps} ref={ref} />;
                  }}
                </LazyImageFull>
                <figcaption className="candidate-mask">
                  <figure className="image is-1by1">
                    <svg width="240" height="240" viewBox="0 0 240 240">
                      <path fill="none" d="M15,14.5 v210 h210"></path>
                      <path fill="none" d="M225,225 v-210 h-210"></path>
                    </svg>
                    <figcaption>
                      <p>詳しく見る</p>
                    </figcaption>
                  </figure>
                </figcaption>
              </picture>
            </figure>
            <div className="candidate-card-title">
              <p className="candidate-card-title-name">{props.entry.name}</p>
              <p className="candidate-card-title-college">{props.entry.college}</p>
            </div>
          </Link>
      }
    </div>
  );
};

export default Card;
