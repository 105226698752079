import * as Navi from 'navi';
import { join } from 'path';
import slugify from 'slugify';
const sortBy = require('lodash.sortby');
const importAll = require('import-all.macro');


// Get a list of all posts, that will not be loaded until the user
// requests them.
const newsModules = importAll.deferred('./**/news.ts?(x)');
const importNews = (pathname: string) => newsModules[pathname]();
const postPathnames = Object.keys(newsModules);
const datePattern = /^((\d{1,4})-(\d{1,4})-(\d{1,4}))[/-]/;

let newsDetails = postPathnames.map(pathname => {
  let slug = slugify(pathname.replace(/news.tsx?$/, '').replace(/(\d)\/(\d)/, '$1-$2'))
    .replace(/^[-.]+|[.-]+$/g, '')
    .replace(datePattern, '$1/');

  let date;
  let dateMatch = slug.match(datePattern);
  if (dateMatch) {
    date = new Date(parseInt(dateMatch[2], 10), parseInt(dateMatch[3], 10) - 1, parseInt(dateMatch[4], 10));
  }

  return {
    slug,
    pathname,
    date,
  };
});

// Sort the pages by slug (which contain the dates)
newsDetails = sortBy(newsDetails, ['slug']).reverse();

// Create url-friendly slugs from post pathnames, and a `getPage()` function
// that can be used to load and return the post's Page object.
let news = newsDetails.map(({ slug, pathname, date }, i) => ({
  getPage: Navi.map(async () => {
    let { default: post } = await importNews(pathname);
    let { title, getContent, ...meta } = post;
    let previousSlug: string, previousPost: {title:string}, nextSlug: string, nextPost: {title:string};

    if (i !== 0) {
      let previousPostDetails = newsDetails[i - 1];
      previousPost = (await importNews(previousPostDetails.pathname)).default;
      previousSlug = previousPostDetails.slug;
    }

    if (i + 1 < newsDetails.length) {
      let nextPostDetails = newsDetails[i + 1];
      nextPost = (await importNews(nextPostDetails.pathname)).default;
      nextSlug = nextPostDetails.slug;
    }

    return Navi.route({
      title,
      getData: (req) => ({
        date,
        pathname,
        slug,
        previousDetails: previousPost && {
          title: previousPost.title,
          href: join('/', 'news', previousSlug),
        },
        nextDetails: nextPost && {
          title: nextPost.title,
          href: join('/', 'news', nextSlug),
        },
        ...meta,
      }),
      getView: async () => {
        let { default: MDXComponent, ...other } = await getContent();
        return { MDXComponent, ...other };
      },
    });
  }),
  slug,
}));

export default news;
