import React from 'react';

interface HistoryProps {
  json: any; // TODO: type
}

const History: React.FC<HistoryProps & React.HTMLAttributes<HTMLDivElement>> = props => (
  <table className="table as-history is-fullwidth is-bordered">
    <thead>
      <tr>
        <th className="has-text-centered">グランプリ</th>
        <th className="has-text-centered">準グランプリ</th>
        <th className="has-text-centered">審査員特別賞</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(props.json)
        .reverse()
        .map((key: string, index: any) => (
          <React.Fragment key={index}>
            <tr>
              <th className="has-text-centered is-narrow" colSpan={3}>
                {(props.json as any)[key]['url'] ? <a className="button is-text is-size-7" href={(props.json as any)[key]['url']} target="_blank">{key}年度</a> : <span className="tag">{key}年度</span>}
              </th>
            </tr>
            <tr>
              <td>
                {(props.json as any)[key]['1st'].map((i: any, index: number) => (
                  <p key={index}>
                    {i.name}
                    <br className="is-hidden-tablet" />
                    <span className="is-size-7 is-padding-5px">{i.college}</span>
                  </p>
                ))}
              </td>
              <td>
                {(props.json as any)[key]['2nd'].map((i: any, index: number) => (
                  <p key={index}>
                    {i.name}
                    <br className="is-hidden-tablet" />
                    <span className="is-size-7 is-padding-5px">{i.college}</span>
                  </p>
                ))}
              </td>
              <td>
                {(props.json as any)[key]['special'].map((i: any, index: number) => (
                  <p key={index}>
                    {i.name}
                    <br className="is-hidden-tablet" />
                    <span className="is-size-7 is-padding-5px">{i.college}</span>
                  </p>
                ))}
              </td>
            </tr>
          </React.Fragment>
        ))}
    </tbody>
  </table>
);

export default History;
