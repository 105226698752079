import React from 'react';

interface MarksProps {}

const Marks: React.FC<MarksProps> = props => (
    <div className="navbar is-clipped">
       <div className="columns is-variable is-4 is-mobile is-centered is-vcentered">
           <div className="column is-narrow">
               <p className="has-text-white-bis is-size-6 has-text-weight-bold">学生団体MARKS</p>
           </div>
           <div className="column is-narrow">
               <a className="button is-size-7-mobile is-black has-text-weight-bold is-margin-top-1 is-margin-bottom-1" href="https://marks-ad.com/contact/students/" target="_blank">
                   <span>団員募集中！</span>
                   <span className="icon">
                       <i className="fas fa-chevron-right"></i>
                   </span>
               </a>
           </div>
       </div>
    </div>
);

export default Marks;
