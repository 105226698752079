import React, { useEffect } from 'react';
import classNames from 'classnames';
import Card from '@mom-web/components/src/card';
import Timeline from '@mom-web/components/src/timeline';
import History from '@mom-web/components/src/history';
import News from '@mom-web/components/src/news';
import Photos from '@mom-web/components/src/photos';
import Twitter from '@mom-web/components/src/twitter';
import Footer from '@mom-web/components/src/footer';
import Header from '@mom-web/components/src/header';
import Marks from '@mom-web/components/src/marks';
import history from '../json/history.json';
import photos from '../json/photo.json';
import news from '../json/news.json';
import entry from '../json/entry.json';
import Entries from '@mom-web/components/src/entries';
import Entry from '@mom-web/components/src/types/entry';
import Information from '@mom-web/components/src/information';

const logo = require('../assets/logo.svg');
const logoColor = require('../assets/logo_color.svg');
const datetime = require('../assets/datetime.svg');
const loadingImage = require('../assets/loading.jpg');

function TopApp() {
  useEffect(() => {
    const query: any = (window as any).$('a[data-fancybox]');
    query &&
      query.fancybox({
        loop: true,
        infobar: false,
        buttons: ['close'],
      });
  }, []);
  return (
    <div id="TopApp">
      <section className="hero is-black has-background-fullscreen">
        <div className="hero-head">
          <Header
            logo={logo}
            datetime={datetime}
            additional={
              <li className="is-hidden-mobile">
                <a href="https://mrofmr.jp/" target="_blank">
                  <span className="icon is-small">
                    <i className="fas fa-external-link-alt" aria-hidden="true" />
                  </span>
                  <span>MR OF MR</span>
                </a>
              </li>
            }
          />
        </div>
        <div className="hero-body is-padding-bottom-1">
          <div className="container has-text-centered">
            <h1 className="title is-margin-top-4-desktop is-margin-top-3-touch is-margin-bottom-5 is-margin-bottom-3-touch is-margin-bottom-2-mobile">
              <img src={logo} alt={process.env.REACT_APP_WEBSITE_NAME} />
            </h1>
            <h2 className="subtitle is-margin-bottom-3">
              <img src={datetime} alt="2020年3月26日(火) マイナビBLITZ赤坂 開催決定!" />
            </h2>
          </div>
        </div>
      </section>
      {/*

        Award

      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">AWARD</h1>
            <h2 className="subtitle">受賞者一覧</h2>
          </div>
          {/* グランプリ */}
          <div className="is-margin-bottom-3">
            <h1 className="title has-text-link is-size-3 as-section-title">グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${29}`}
                entry={{
                  id: 29,
                  mysta: '',
                  showroom: 'mom20_miss_29',
                  college: '日本大学',
                  name: '西脇 萌',
                  furigana: 'にしわき もえ',
                  hometown: '東京都',
                  grade: 2,
                  department: '文理学部',
                  twitter: 'ohrei19_miss01',
                  instagram: '__nishiwakimoe',
                  hobby: '犬と遊ぶこと',
                  skill: '初対面の人とも楽しく話せること',
                  dream: '芸能活動を考えています！',
                  enthusiasm: '最後まで全力で皆さんと楽しみたいです！',
                  circle: '',
                  magazine: 'non-no、Ray',
                  birthday: '2000/03/23',
                  images: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  finalist: true,
                }}
              />
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="is-margin-bottom-3">
            <h1 className="title is-size-5 as-section-title">準グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${3}`}
                entry={{
                  id: 3,
                  mysta: '',
                  showroom: 'mom20_miss_3',
                  college: '千葉大学',
                  name: '森下 花音',
                  furigana: 'もりした かのん',
                  hometown: '千葉県',
                  grade: 1,
                  department: '教育学部',
                  twitter: 'ChibaMs2019_No2',
                  instagram: 'ChibaMs2019_No2',
                  hobby: '踊ること、音楽をきくこと',
                  skill: 'チアダンス',
                  dream: '幸せな家族❤︎',
                  enthusiasm:
                    '支えて下さる全ての方々への感謝の気持ちを忘れず､今しかできないことを全力で､最後まで自分らしくがんばります!',
                  circle: '競技チアダンス',
                  magazine: 'ar、Ray',
                  birthday: '2000/05/28',
                  images: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  finalist: true,
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${16}`}
                entry={{
                  id: 16,
                  mysta: '',
                  showroom: 'mom20_miss_16',
                  college: '成蹊大学',
                  name: '松本 楓加',
                  furigana: 'まつもと ふうか',
                  hometown: '埼玉県',
                  grade: 3,
                  department: '経済学部経済経営学科',
                  twitter: 'fuka_matsumoto_',
                  instagram: '',
                  hobby: '最近は可愛い子の画像を見つけて保存し、そのフォルダを眺めることです。',
                  skill: 'ダンス',
                  dream: 'ショープランナー',
                  enthusiasm: '自分を忘れずに、みなさんと楽しみながら頑張ります！',
                  circle: '',
                  magazine: 'FUDGE ar  mina LARME ',
                  birthday: '1998/09/23',
                  images: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  finalist: true,
                }}
              />
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="is-margin-bottom-3">
            <h1 className="title is-size-5 as-section-title">審査員特別賞</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-multiline is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${26}`}
                entry={{
                  "id": 26,
                  "mysta": "",
                  "showroom": "mom20_miss_26",
                  "college": "東洋大学",
                  "name": "仲原 舞子",
                  "furigana": "なかはら まいこ",
                  "hometown": "神奈川県",
                  "grade": 3,
                  "department": "経済学部",
                  "twitter": "misstoyo19_03",
                  "instagram": "misstoyo19_03",
                  "hobby": "映画鑑賞、魚図鑑を眺めること、芥川賞受賞作品を読むこと",
                  "skill": "人を笑顔にすること、水泳、書道",
                  "dream": "老若男女に愛されるモデル・女優業",
                  "enthusiasm": "",
                  "circle": "国際交流サークルJUB",
                  "magazine": "書店にあるファッション誌全般",
                  "birthday": "1998/08/14",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${31}`}
                entry={{
                  "id": 31,
                  "mysta": "",
                  "showroom": "mom20_miss_31",
                  "college": "立命館大学",
                  "name": "中林 奈々",
                  "furigana": "なかばやし なな",
                  "hometown": "三重県",
                  "grade": 2,
                  "department": "経済学部",
                  "twitter": "mcr2019_nn5",
                  "instagram": "mcr2019_nn5",
                  "hobby": "タピオカ巡り、咀嚼音をきくこと",
                  "skill": "バレエ、目ん玉リレー",
                  "dream": "グランドスタッフ",
                  "enthusiasm": "この活動を自分自身が精一杯楽しむ！",
                  "circle": "バレーボール、サッカーマネージャー",
                  "magazine": "CanCam",
                  "birthday": "1999/02/04",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${32}`}
                entry={{
                  "id": 32,
                  "mysta": "",
                  "showroom": "mom20_miss_32",
                  "college": "立命館大学",
                  "name": "西尾 ゆい",
                  "furigana": "にしお ゆい",
                  "hometown": "愛知県",
                  "grade": 2,
                  "department": "国際関係学部",
                  "twitter": "mcr2019_ny2",
                  "instagram": "mcr2019_ny2",
                  "hobby": "大食いすること",
                  "skill": "早食いです",
                  "dream": "チャイルドライフスペシャリスト",
                  "enthusiasm": "また最後まで応援してくれる皆さんと一緒に頑張りたいです！",
                  "circle": "",
                  "magazine": "",
                  "birthday": "2000/03/10",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>
          {/* ミスリゼクリニック賞/ミスリゼウォーク賞 */}
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">ミス<br className="is-hidden-tablet" />リゼクリニック賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${32}`}
                entry={{
                  "id": 32,
                  "mysta": "",
                  "showroom": "mom20_miss_32",
                  "college": "立命館大学",
                  "name": "西尾 ゆい",
                  "furigana": "にしお ゆい",
                  "hometown": "愛知県",
                  "grade": 2,
                  "department": "国際関係学部",
                  "twitter": "mcr2019_ny2",
                  "instagram": "mcr2019_ny2",
                  "hobby": "大食いすること",
                  "skill": "早食いです",
                  "dream": "チャイルドライフスペシャリスト",
                  "enthusiasm": "また最後まで応援してくれる皆さんと一緒に頑張りたいです！",
                  "circle": "",
                  "magazine": "",
                  "birthday": "2000/03/10",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">ミス<br className="is-hidden-tablet" />リゼウォーク賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${36}`}
                entry={{
                  "id": 36,
                  "mysta": "",
                  "showroom": "mom20_miss_36",
                  "college": "東京大学",
                  "name": "中澤 莉佳子",
                  "furigana": "なかざわ りかこ",
                  "hometown": "神奈川県",
                  "grade": 3,
                  "department": "工学部",
                  "twitter": "misstodai_19_05",
                  "instagram": "misstodai_19_05",
                  "hobby": "アイドルの振りコピ、ゲーム、睡眠",
                  "skill": "料理、大食い",
                  "dream": "幸せになる",
                  "enthusiasm": "出遅れましたが本気です",
                  "circle": "東大娘。'20",
                  "magazine": "",
                  "birthday": "1998/11/28",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>
            {/* モデルプレス賞/MISS COLLE賞 */}
            <div className="columns is-centered is-mobile is-margin-bottom-6">
                <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
                    <div className="is-margin-bottom-3">
                        <h1 className="title is-size-5 is-size-6-mobile as-section-title">モデルプレス賞</h1>
                        <h2 className="subtitle" />
                    </div>
                    <Card
                        loadingImage={loadingImage}
                        href={`/profile/${36}`}
                        entry={{
                            "id": 36,
                            "mysta": "",
                            "showroom": "mom20_miss_36",
                            "college": "東京大学",
                            "name": "中澤 莉佳子",
                            "furigana": "なかざわ りかこ",
                            "hometown": "神奈川県",
                            "grade": 3,
                            "department": "工学部",
                            "twitter": "misstodai_19_05",
                            "instagram": "misstodai_19_05",
                            "hobby": "アイドルの振りコピ、ゲーム、睡眠",
                            "skill": "料理、大食い",
                            "dream": "幸せになる",
                            "enthusiasm": "出遅れましたが本気です",
                            "circle": "東大娘。'20",
                            "magazine": "",
                            "birthday": "1998/11/28",
                            "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            "finalist": true
                        }}
                    />
                </div>
                <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
                    <div className="is-margin-bottom-3">
                        <h1 className="title is-size-5 is-size-6-mobile as-section-title">MISS COLLE賞</h1>
                        <h2 className="subtitle" />
                    </div>
                    <Card
                        loadingImage={loadingImage}
                        href={`/profile/${10}`}
                        entry={{
                            "id": 10,
                            "mysta": "",
                            "showroom": "mom20_miss_10",
                            "college": "神戸学院大学",
                            "name": "柿木 風花",
                            "furigana": "かきぎ ふうか",
                            "hometown": "兵庫県",
                            "grade": 3,
                            "department": "グローバルコミュニケーション",
                            "twitter": "mskgu_no1",
                            "instagram": "11.kakigi_fuka.19",
                            "hobby": "温泉巡り",
                            "skill": "表現すること",
                            "dream": "海外で仕事をすること",
                            "enthusiasm": "せっかくいただいたチャンスを叶えれるよう誰にも負けない自分らしさを最後まで貫いて1人でも多くの人と繋がることで1人でも多くの人に勇気や強さを届けれる存在になれたらと思っております。",
                            "circle": "",
                            "magazine": "JJ",
                            "birthday": "1998/11/19",
                            "images": [1, 2, 3, 4, 5, 6, 7, 8, 9],
                            "finalist": true
                        }}
                    />
                </div>
            </div>
          {/* フジテレビアナトレ賞/mysta賞/DHC賞 */}
          <div className="columns is-centered is-multiline is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">フジテレビ<br className="is-hidden-tablet" />アナトレ賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${20}`}
                entry={{
                  "id": 20,
                  "mysta": "",
                  "showroom": "mom20_miss_20",
                  "college": "関西学院大学",
                  "name": "片山 莉紗子",
                  "furigana": "かたやま りさこ",
                  "hometown": "三重県",
                  "grade": 2,
                  "department": "経済学部",
                  "twitter": "mckg2019_kr",
                  "instagram": "mckg2019_kr",
                  "hobby": "カフェ巡り",
                  "skill": "バトントワリング",
                  "dream": "アナウンサー",
                  "enthusiasm": "関西から全国に向けて、笑顔で頑張りたいと思います！",
                  "circle": "KG CLUB",
                  "magazine": "ar、sweet",
                  "birthday": "2000/01/05",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title"><br className="is-hidden-tablet" />mysta賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${16}`}
                entry={{
                  "id": 16,
                  "mysta": "",
                  "showroom": "mom20_miss_16",
                  "college": "成蹊大学",
                  "name": "松本 楓加",
                  "furigana": "まつもと ふうか",
                  "hometown": "埼玉県",
                  "grade": 3,
                  "department": "経済学部経済経営学科",
                  "twitter": "fuka_matsumoto_",
                  "instagram": "",
                  "hobby": "最近は可愛い子の画像を見つけて保存し、そのフォルダを眺めることです。",
                  "skill": "ダンス",
                  "dream": "ショープランナー",
                  "enthusiasm": "自分を忘れずに、みなさんと楽しみながら頑張ります！",
                  "circle": "",
                  "magazine": "FUDGE ar  mina LARME ",
                  "birthday": "1998/09/23",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title"><br className="is-hidden-tablet" />DHC賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${22}`}
                entry={{
                  "id": 22,
                  "mysta": "",
                  "showroom": "mom20_miss_22",
                  "college": "近畿大学",
                  "name": "清水 彩未",
                  "furigana": "しみず あやみ",
                  "hometown": "岡山県",
                  "grade": 4,
                  "department": "国際学部",
                  "twitter": "aym_ms19_6",
                  "instagram": "a_y_a_m_i_22",
                  "hobby": "ドラマ鑑賞、アイス食べ比べ",
                  "skill": "サックス",
                  "dream": "多くの人の日々の生活に良い影響を与えられる人、モデルになる。",
                  "enthusiasm": "応援して下さった、そしてこれから応援して下さる皆さんへの感謝の気持ちを忘れずに。自分の夢を追いかけつつ、近畿大学のミスコンを盛り上げていきます。応援よろしくお願いします！",
                  "circle": "",
                  "magazine": "JELLY、ViVi",
                  "birthday": "1997/05/13",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>

        </div>
      </section>
      {/*

        Entries

      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Finalist</h1>
            <h2 className="subtitle">ファイナリスト紹介</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries list={entry.list as any} finalist>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                      'is-hidden-tablet-only': index === 3,
                    })}
                    key={item.id}
                  >
                    <Card loadingImage={loadingImage} href={`/profile/${item.id}`} entry={item} />
                  </div>
                ))
              }
            </Entries>
          </div>
        </div>
      </section>
      {/*

        Information

      */}
      <section id="information" className="section">
        <div className="container" style={{ maxWidth: 740 }}>
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Information</h1>
            <h2 className="subtitle">イベント情報</h2>
          </div>
          <p className="has-text-centered is-size-5 has-text-weight-bold is-margin-bottom-0-5">
            MISS OF MISS CAMPUS QUEEN CONTEST 2020 supported by リゼクリニック
          </p>
          <p className="has-text-centered is-size-5 has-text-weight-bold is-margin-bottom-3">
            MR OF MR CAMPUS CONTEST 2020 supported by メンズリゼ
          </p>
          <Information />
        </div>
      </section>
      {/*

        Schedule

      */}
      <section id="schedule" className="section has-background-gradient">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Schedule</h1>
            <h2 className="subtitle is-margin-bottom-1-5">日本一のミスキャンパスが決まるまで</h2>
            <p className="is-size-7 has-text-white has-text-centered">
              ※ スケジュールは予告なく変更されることがあります。
            </p>
          </div>
          <Timeline />
        </div>
      </section>
      {/*

        Photo

      */}
      <section id="photo" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Photo</h1>
            <h2 className="subtitle">表彰式の様子</h2>
          </div>
            <Photos photos={photos} loadingImage={loadingImage} />
        </div>
      </section>

      {/*

        History

      */}
      <section id="history" className="section has-background-gradient-history">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">History</h1>
            <h2 className="subtitle">ミスオブミスの17年の歴史</h2>
          </div>
          <History json={history} />
        </div>
      </section>

      {/*

         News

      */}
      <section id="news" className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="is-margin-bottom-0">
                <h1 className="title as-section-title">News</h1>
                <h2 className="subtitle">お知らせ</h2>
              </div>
              <News json={news} />
            </div>
            <div className="column">
              <div className="is-margin-bottom-3">
                <h1 className="title as-section-title">FOLLOW</h1>
                <h2 className="subtitle">最新情報</h2>
                <Twitter />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Marks />
      <Footer src={logoColor} />
    </div>
  );
}

export default TopApp;
