import React from 'react';

interface TimelineProps {
}

const Timeline: React.FC<TimelineProps & React.HTMLAttributes<HTMLDivElement>> = (props) => (
    <div className="timeline">
        <table className="table as-timeline">
            <tbody>
            <tr>
                <td className="is-narrow">
                    <svg width="20px">
                        <rect x="6.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white"></rect>
                        <line x1="12" y1="23" x2="10" y2="1500" stroke="white" strokeWidth="1"></line>
                    </svg>
                </td>
                <td className="centered is-padding-bottom-3">
                    <div>
                        <p className="is-size-4">全国予選</p>
                        <p>2019/12/27(金)〜2020/02/24(月)</p>
                        <p className="tag is-light is-link is-margin-top-1 is-margin-bottom-0-5">WEB投票</p>
                        <p>2019/12/27(金)22:00<br className="is-hidden-tablet" />〜2020/2/24(月)21:59</p>
                        <p className="tag is-light is-link  is-margin-top-1 is-margin-bottom-0-5">mysta審査</p>
                        <p>① 2020/1/8(水)12:00<br className="is-hidden-tablet" />〜1/15(水)21:59</p>
                        <p>② 2020/2/13(木)12:00<br className="is-hidden-tablet" />〜2/24(月)21:59</p>
                        <p className="tag is-light is-link is-margin-top-1 is-margin-bottom-0-5">SHOWROOM審査</p>
                        <p>2月14日(金)12:00<br className="is-hidden-tablet" />～2月23日(日)21:59</p>
                        <p className="tag is-light is-link is-margin-top-1 is-margin-bottom-0-5">特別協賛審査</p>
                        <p>2020/2/13(木)12:00<br className="is-hidden-tablet" />〜2/24(月)21:59</p>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="is-narrow">
                    <svg width="20px">
                        <rect x="5.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white"></rect>
                    </svg>
                </td>
                <td className="centered">
                    <div>
                        <p className="is-size-4">決勝期間</p>
                        <p>2020/03/02(月)〜2020/03/22(日)</p>
                        <p className="tag is-light is-link is-margin-top-1 is-margin-bottom-0-5">WEB投票</p>
                        <p>2020/03/02(月)12:00<br className="is-hidden-tablet" />〜2020/3/22(日)21:59</p>
                        <p className="tag is-light is-link  is-margin-top-1 is-margin-bottom-0-5">mysta審査</p>
                        <p>2020/03/13(金)12:00<br className="is-hidden-tablet" />〜2020/3/21(土)21:59</p>
                        <p className="tag is-light is-link is-margin-top-1 is-margin-bottom-0-5">SHOWROOM審査</p>
                        <p>① 2020/03/03(火)12:00<br className="is-hidden-tablet" />〜03/08(日)21:59</p>
                        <p>② 【前半】3/16(月)18:00<br className="is-hidden-tablet" />～3/17(火)21:59</p>
                        <p>② 【後半】3/19(木)18:00<br className="is-hidden-tablet" />〜3/22(日)21:59</p>
                        <p className="tag is-light is-link is-margin-top-1 is-margin-bottom-0-5">特別協賛審査</p>
                        <p>2020/03/09(月)12:00<br className="is-hidden-tablet" />〜03/22(日)21:59</p>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="is-narrow">
                    <svg width="20px">
                        <rect x="5.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white"></rect>
                    </svg>
                </td>
                <td className="centered">
                    <div>
                        <p className="is-size-4">表彰式</p>
                        <p>2020/03/26(木)<br />@マイナビBLITZ赤坂</p>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

);

export default Timeline;