import React, { useState } from 'react';
import classNames from 'classnames';
export default function Voted(props: { state?: string; message?: string } & React.ImgHTMLAttributes<HTMLImageElement>) {
  const [active, setActive] = useState<boolean>(true);
  if (!props.state) {
    return null;
  }
  let message = '';
  if (props.state === 'complete') {
    message = 'WEB投票が完了しました';
  } else if (props.state === 'failed') {
    message = 'WEB投票に失敗しました';
  }
  if (!message) {
    return null;
  }
  return (
    <div
      className={classNames('modal voted', {
        'is-active': active,
      })}
    >
      <div
        className="modal-background has-background-gradient"
        onClick={function() {
          return close();
        }}
      />
      <div className="modal-card">
        <header className="modal-card-head is-padding-top-0-5 is-padding-left-1 is-padding-right-0-5"></header>
        <section className="modal-card-body has-text-centered">
          <figure className="image is-128x128 is-margin-bottom-2">
            <img className="is-rounded" src={props.src} alt={props.alt} />
            {props.state === 'complete' && (
              <span className="icon is-large ">
                <i className="fas fa-2x fa-check-circle"></i>
              </span>
            )}
          </figure>
          <h1 className="title has-text-weight-normal is-size-5 is-margin-bottom-1">{message}</h1>
          <p
            className={classNames('tag is-size-7 has-text-weight-bold', {
              'is-danger': props.state === 'failed',
              'is-success': props.state === 'complete',
            })}
          >
            {props.message}
          </p>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-fullwidth is-white is-light" onClick={() => close()}>閉じる</button>
        </footer>
      </div>
    </div>
  );
  function close() {
    window.history.replaceState('', '', location.pathname);
    setActive(false);
  }
}
