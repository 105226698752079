import React, { useState, useEffect } from 'react';
import shuffle from 'lodash/shuffle';
import Entry from '../types/entry';

interface EntriesProps {
  list: Entry[];
  children: (list: any[]) => any;
  size?: number;
  finalist?: boolean
}

const Entries: React.FC<EntriesProps> = ({list, size, children, finalist}) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<Entry[]>([]);
  useEffect(() => {
    let result: Entry[] = shuffle(finalist? list.filter(entry => entry.finalist) :list);
    if (size) {
      result = result.slice(0, size <= list.length ? size : list.length + 1);
    }
    setResult(result);
    setLoading(false);
  }, [finalist]);
  if (loading) {
    return (
      <div className="column">
        <p className="has-text-centered">
          <a className="button is-loading is-text is-large" style={{ height: 240 }} />
        </p>
      </div>
    );
  }
  return <>{children(result)}</>;
};

export default Entries;
