import React from 'react';
import { View } from 'react-navi';
import ReactGA from 'react-ga';
import { mount, compose, withView, route as naviRoute } from 'navi';
import EntriesApp from './apps/EntriesApp';
import TopApp from './apps/TopApp';
import profiles from './profiles';
import news from './news'
import NewsApp from "./apps/NewsApp";

const fromPairs = require('lodash.frompairs');

export default compose(
  withView((request: any) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLED === 'true') {
      console.log('[pageview]', request.path);
      ReactGA.pageview(request.path);
    }
    return <View />;
  }),
  mount({
    '/': naviRoute({
      title: process.env.REACT_APP_WEBSITE_NAME,
      getView: () => TopApp,
    }),
    '/profile':mount({
      ...fromPairs(profiles.map(profile => [`/${profile.id}`, profile.getPage])),
    }),
    '/entries': naviRoute({
      title: `エントリー一覧 | ` + process.env.REACT_APP_WEBSITE_NAME,
      getView: () => <EntriesApp />,
    }),
    '/finalist': naviRoute({
      title: `ファイナリスト一覧 | ` + process.env.REACT_APP_WEBSITE_NAME,
      getView: () => <EntriesApp finalist />,
    }),
    '/news': compose(
        withView((req) => <NewsApp />),
        mount(fromPairs(news.map(post => ['/' + post.slug, post.getPage])))
    )
  }),
);
