import React, { useEffect } from 'react';
import Footer from '@mom-web/components/src/footer';
import entry from '../json/entry.json';
import Card from '@mom-web/components/src/card';
import Entries from '@mom-web/components/src/entries';
import Entry from '@mom-web/components/src/types/entry';
import Header from '@mom-web/components/src/header';
import Photo from '@mom-web/components/src/photo';
import TwitterButton from '@mom-web/components/src/twitterButton';
import InstagramButton from '@mom-web/components/src/instagramButton';
import { Helmet } from 'react-navi-helmet-async';
import { Link, useCurrentRoute } from 'react-navi';
import classNames from 'classnames';
import Voted from '@mom-web/components/src/voted';
const logo = require('../assets/logo.svg');
const logoColor = require('../assets/logo_color.svg');
const datetime = require('../assets/datetime.svg');
const loadingImage = require('../assets/loading.jpg');

function ProfileApp(props: { entry: Entry }) {
  const {
    url: { query },
  } = useCurrentRoute();
  useEffect(() => {
    const query: any = (window as any).$('a[data-fancybox]');
    query &&
      query.fancybox({
        loop: true,
        infobar: false,
        buttons: ['close'],
      });
  }, []);

  const src =
    props.entry.images.length > 0
      ? `/image/entries/${props.entry.id}/${props.entry.images[0]}.jpg`
      : '/image/noimage.jpg';
  const thumbnails = props.entry.images.slice(0, props.entry.images.length - 1);
  return (
    <div id="ProfileApp">
      <Helmet>
        <meta content={`${props.entry.name} | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title" />
        <meta content={`${process.env.REACT_APP_URL}${src}`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/profile/${props.entry.id}`} property="og:url" />
      </Helmet>
      <Voted {...query} src={src} />
      <Header
        logo={logo}
        datetime={datetime}
        additional={
          <li className="is-hidden-mobile">
            <a href="https://mrofmr.jp/" target="_blank">
              <span className="icon is-small">
                <i className="fas fa-external-link-alt" aria-hidden="true" />
              </span>
              <span>MR OF MR</span>
            </a>
          </li>
        }
      />
      {/* Section */}
      <section className="section as-profile">
        <div className="container">
          <div className="columns is-variable is-8 ">
            <div className="column">
              <Photo href={src} src={src} loadingImage={loadingImage} />
              {thumbnails.length > 0 && (
                <div className="columns is-mobile is-multiline is-variable is-hidden-mobile as-profile-photo-list">
                  {thumbnails.map(thumbnail => (
                    <div key={thumbnail} className="column is-one-fifth">
                      <Photo
                        onBackground
                        loadingImage={loadingImage}
                        href={`/image/entries/${props.entry.id}/${props.entry.images[thumbnail]}.jpg`}
                        src={`/image/entries/${props.entry.id}/${props.entry.images[thumbnail]}.jpg`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="column">
              {props.entry.finalist && <p className="tag is-link is-light">ファイナリスト</p>}
              <div className="columns is-mobile is-variable is-size-3 is-2 is-wrap">
                <div className="column is-narrow" style={{ maxWidth: '100%' }}>
                  <h1>{props.entry.name}</h1>
                </div>
                {props.entry.instagram && (
                  <div className="column is-narrow">
                    <InstagramButton
                      href={`https://instagram.com/${props.entry.instagram}`}
                      target="_blank"
                      style={{ marginTop: 3 }}
                    />
                  </div>
                )}
                {props.entry.twitter && (
                  <div className="column is-narrow">
                    <TwitterButton
                      href={`https://twitter.com/${props.entry.twitter}`}
                      target="_blank"
                      style={{ marginTop: 3 }}
                    />
                  </div>
                )}
              </div>
              {/* Detail */}
              <div className="is-margin-bottom-1">
                <p className="has-text-link">大学・学部・学年</p>
                <p className="is-size-5">
                  {props.entry.college} {props.entry.department} {props.entry.grade}年
                </p>
              </div>
              {props.entry.birthday && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-link">生年月日</p>
                  <p className="is-size-5">{props.entry.birthday}</p>
                </div>
              )}
              {props.entry.hometown && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-link">出身地</p>
                  <p className="is-size-5">{props.entry.hometown}</p>
                </div>
              )}
              {props.entry.finalist && (
                <div className="has-text-centered is-margin-bottom-1">
                  <a
                    className="button is-fullwidth is-gorgeous is-large"
                    href="https://www.rizeclinic.com/missmrcampus2019/missofmiss-vote"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span className="icon icon-crown" />
                    <span>WEB投票サイト</span>
                  </a>
                </div>
              )}
              {/*    */}
              {props.entry.hobby && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">趣味</p>
                  <p>{props.entry.hobby}</p>
                </div>
              )}
              {props.entry.skill && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">特技</p>
                  <p>{props.entry.skill}</p>
                </div>
              )}
              {props.entry.magazine && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">好きな雑誌</p>
                  <p>{props.entry.magazine}</p>
                </div>
              )}
              {props.entry.dream && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">将来の夢</p>
                  <p>{props.entry.dream}</p>
                </div>
              )}
              {props.entry.enthusiasm && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">意気込み</p>
                  <p>{props.entry.enthusiasm}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/*

        Entries

      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Entries</h1>
            <h2 className="subtitle">出場者紹介</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries list={entry.list as any} size={4}>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                      'is-hidden-tablet-only': index === 3,
                    })}
                    key={item.id}
                  >
                    <Card nativeLink loadingImage={loadingImage} href={`/profile/${item.id}`} entry={item} />
                  </div>
                ))
              }
            </Entries>
          </div>
          <div className="has-text-centered">
            <Link className="button is-gorgeous is-large" href="/entries">
              <span className="icon icon-crown"></span>
              <span>他の出場者を見る</span>
            </Link>
          </div>
        </div>
      </section>
      <Footer src={logoColor} />
    </div>
  );
}

export default ProfileApp;
