import React from "react";

interface FooterProps {
    
}

const Footer: React.FC<FooterProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & React.ImgHTMLAttributes<HTMLImageElement>> = (props) => (
    <footer className="footer has-background-black is-padding-bottom-2-5">
        <div className="content has-text-centered">
            <img src={props.src} />
            <p className="is-size-7">
                Copyright © 2019- 学生団体MARKS / 株式会社エイジ・エンタテインメント All Rights Reserved.
            </p>
        </div>
        <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
            <span className="icon"><i className="far fa-envelope"></i></span><span>お問い合わせ</span>
        </a>
    </footer>
);

export default Footer;
