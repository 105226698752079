import React from 'react';
import Footer from '@mom-web/components/src/footer';
import entry from '../json/entry.json';
import Card from '@mom-web/components/src/card';
import Entries from '@mom-web/components/src/entries';
import Entry from '@mom-web/components/src/types/entry';
import Header from '@mom-web/components/src/header';
import { Link } from 'react-navi';
const logo = require('../assets/logo.svg');
const logoColor = require('../assets/logo_color.svg');
const datetime = require('../assets/datetime.svg');
const loadingImage = require('../assets/loading.jpg');

function EntriesApp({ finalist }: { finalist?: boolean }) {
  const title = finalist ? 'Finalist' : 'Entries';
  const subtitle = finalist ? 'ファイナリスト一覧' : '出場者一覧';
  const buttonTitle = finalist ? 'その他の出場者を見る' : 'ファイナリスト一覧を見る';
  const buttonHref = finalist ? '/entries' : '/finalist';
  return (
    <div id="EntriesApp">
      <Header
        logo={logo}
        datetime={datetime}
        additional={
          <li className="is-hidden-mobile">
            <a href="https://mrofmr.jp/" target="_blank">
              <span className="icon is-small">
                <i className="fas fa-external-link-alt" aria-hidden="true" />
              </span>
              <span>MR OF MR</span>
            </a>
          </li>
        }
      />
      {/* Entries */}
      <section id="entries" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries list={entry.list as any} finalist={finalist}>
              {(list: Entry[]) =>
                list.map((item: Entry) => (
                  <div className="column is-3-desktop is-4-tablet is-6-mobile" key={item.id}>
                    <Card loadingImage={loadingImage} href={`/profile/${item.id}`} entry={item} />
                  </div>
                ))
              }
            </Entries>
          </div>
          <div className="has-text-centered">
            <Link className="button is-gorgeous is-large" href={buttonHref}>
              <span className="icon icon-crown" />
              <span>{buttonTitle}</span>
            </Link>
          </div>
        </div>
      </section>
      <Footer src={logoColor} />
    </div>
  );
}

export default EntriesApp;
