import React, { useState, useMemo } from 'react';
import Photo from '../photo';
import { Link } from 'react-navi';

interface PhotosProps {
  photos: any;
  loadingImage: any;
}

const Photos: React.FC<PhotosProps> = ({ photos, loadingImage }) => {
  const [open, setOpen] = useState(false);
  const preview = useMemo(() => photos.slice(0, 12), [photos]);
  const more = useMemo(() => photos.slice(12, 100), [photos]);
  return (
    <>
      <div className="columns as-photo-list is-multiline is-mobile is-variable is-1 is-margin-bottom-3">
        {preview.map((item: any, index: number) => (
          <div key={index} className="column is-2-desktop is-3-tablet is-4-mobile">
            <Photo href={item.image} src={item.image} loadingImage={loadingImage} onBackground />
          </div>
        ))}
        {open &&
          more.map((item: any, index: number) => (
            <div key={index} className="column is-2-desktop is-3-tablet is-4-mobile">
              <Photo href={item.image} src={item.image} loadingImage={loadingImage} onBackground />
            </div>
          ))}
      </div>
      {!open && (
        <div className="has-text-centered">
          <a className="button is-gorgeous is-large" onClick={() => setOpen(true)}>
            <span className="icon icon-crown"></span>
            <span>写真をもっと見る</span>
          </a>
        </div>
      )}
    </>
  );
};

export default Photos;
